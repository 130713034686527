<template>
    <div class="h-100 d-flex align-items-center">
        <b-form-input class="w-100 h-100" type="number" ref="input" v-model.number="value" min="0" step="0.01" @keydown="(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()"/>
    </div>
</template>
<script>
    import {countDecimals} from '@/libs/helpers'

    const {nextTick} = require('@vue/composition-api')
    import { BFormInput } from 'bootstrap-vue'

    export default {
        components: {BFormInput},
        data() {
            return {
                value: null,
                unit: ''
            }
        },
        computed:{
            money_types() {
                return this.$store.getters['ingredients/moneyTypes']
            },
            weight_types() {
                return this.$store.getters['ingredients/weightTypes']
            },
            volume_types() {
                return this.$store.getters['ingredients/volumeTypes']
            },
            piece_types() {
                return this.$store.getters['ingredients/pieceTypes']
            }
        },
        methods: {
            convertPackagingToUnit(packaging, unit) {
                let type = null
                if (unit === 'mL' || unit === 'dL' || unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === unit)
                } else if (unit === 'g' || unit === 'dag' || unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === unit)
                } else if (unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === unit)
                }

                if (!type) {
                    return 0
                }

                const allowedDecimals = Math.log10(type.factor)
                const stock = Number(Number(packaging) / Number(type.factor)).toFixed(allowedDecimals).replace(`.${  '0'.repeat(allowedDecimals)}`, '')

                return stock
            },
            convertPackagingToDefault(packaging, unit) {
                let type = null
                if (unit === 'mL' || unit === 'dL' || unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === unit)
                } else if (unit === 'g' || unit === 'dag' || unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === unit)
                } else if (unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === unit)
                }

                if (!type) {
                    return 0
                }

                const decimals = countDecimals(this.value)
                const allowedDecimals = Math.log10(type.factor)
                if (allowedDecimals < decimals) {
                    this.$printWarning(`${this.$t('print.warning.chosen_ingredient_1')} ${  allowedDecimals} ${this.$t('print.warning.chosen_ingredient_2')}`)
                    return
                }

                const stock = Number(Number(this.value) * Number(type.factor)).toFixed(allowedDecimals).replace(`.${  '0'.repeat(allowedDecimals)}`, '')

                return stock
            },
            getValue() {
                return this.value
            },
            isCancelBeforeStart() {
                return false
            },
            isCancelAfterEnd() {
                this.value = this.convertPackagingToDefault(this.value, this.unit)

                return false
            }
        },
        mounted() {
            this.unit  = this.params.data.packaging_unit
            this.value = this.convertPackagingToUnit(this.params.data.stock, this.params.data.packaging_unit)

            nextTick(() => this.$refs.input.focus())
        }
    }

</script>