<template>
    <div>
        <div v-if="params.multiple">
            <b-modal :title="params.column.colDef.headerName" v-model="modalActive" :scrollable="false" centered no-close-on-backdrop @hide="params.api.stopEditing(true)">
                <template #default>
                    <TableWithCheckBox :columnDefs="columnDefs" :rowData="arrayOfData" :selected="value" :selectValue="params.selectValue" :findValueInArray="params.findValueInArray" @selectionChanged="selectionChanged"/>
                </template>
                <template #modal-footer>
                    <b-button variant="primary" @click="params.api.stopEditing()">
                        <feather-icon icon="EditIcon" class="mr-50"/>
                        <span class="align-middle">{{ $t('general.edit') }}</span>
                    </b-button>
                </template>
            </b-modal>
        </div>
        <div v-else class="outer">
            <v-select id="table-select" appendToBody :calculatePosition="calculatePosition" :class="[open ? 'display-open':'display-close']" style="height: 42px;" :closeOnSelect="true" @open="isOpen" @close="onChange" @input="onChange" ref="select" v-model="value" label="name" :multiple="params.multiple ? true:false" :options="arrayOfData" :reduce="item => item.id">
            </v-select>
        </div>
    </div>
</template>
<script>
    import vSelect from 'vue-select'
    import {nextTick} from '@vue/composition-api'
    import TableWithCheckBox from '@/views/components/Table/TableWithCheckBox'
    import {BModal, BButton} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {vSelect, TableWithCheckBox, BModal, BButton},
        data() {
            return {
                value: null,
                arrayOfData: [],
                open: false,
                columnDefs: [{ headerName: this.$t('table_fields.select'), field: 'name', editable: false, filter: true }],
                modalActive: false
            }
        },
        methods: {
            calculatePosition(dropdownList, component, {width, top, left}) {
                dropdownList.style.top = top
                dropdownList.style.left = left
                dropdownList.style.width = 'auto'
                dropdownList.style.minWidth = width
            },
            getValue() {
                return this.value
            },
            isCancelBeforeStart() {
                return false
            },
            async getValues() {
                try {
                    const response = await this.$http.get(`/api/client/v1/${this.params.values}/`)
                    this.arrayOfData = response.data
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(`${this.$t('print.error.on_load_data')}\n${err.message}`)
                }
            },
            isOpen() {
                this.open = true
            },
            onChange() {
                nextTick(() => {
                    this.params.stopEditing()
                })
            },
            selectionChanged(val) {
                this.value = val
            }
        },
        async mounted() {
            if (Array.isArray(this.params.values)) this.arrayOfData = this.params.values
            else await this.getValues()
            this.value = this.params.value
            /*if (this.params.invoice) {
                this.arrayOfData = this.arrayOfData.filter(ele => ele.id > this.value)
            }*/
            if (this.params.multiple) {
                this.modalActive = true
            } else {
                this.$refs.select.open = true
                nextTick(() => {
                    const input = this.$refs.select.$el.querySelector('input')
                    input.focus()
                })
            }
        }
    }
</script>
<style scoped>
.outer {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  background-color: #fff;
  height: 42px;
  text-align: center;
  display: inline-block;
  outline: none;
  min-width: 100%;
}
</style>
<style>
#table-select .vs__dropdown-toggle {
  height: 40px;
}
#table-select .vs__selected {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 80px;
  padding-top: 2px;
}
.display-open .vs__selected {
  display: flex;
}
.display-open .vs__dropdown-menu {
  top: 40px;
}
.display-close .vs__selected {
  display: block !important;
}
 .vs__dropdown-option{
        padding-right: 20px !important;
        /* width: 1000px; */
    }
</style>